import axios from 'axios';
import toast from 'react-hot-toast';
import domainStore from '../stores/domain';
import { clerk } from './clerk';

const baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://app-aida-prod-westeurope.azurewebsites.net';

const client = axios.create({ baseURL });

// Define your interceptor
client.interceptors.request.use(
  async (config: any) => {
    const activeDomain = domainStore.getState().domain;
    // Update auth headers
    const token = (await clerk.session!.getToken()) as string;
    config.headers.authorization = `Bearer ${token}`;
    config.headers.domain = activeDomain;
    return config;
  },
  (error) => {
    // Handle request errors here
    toast.error(error.response.data?.message || `Er ging iets mis`);
    console.error(error);
  },
);

client.interceptors.response.use(
  (response) => {
    if (response.status === 201) {
      // trim quotes from start and end of message
      toast.success(JSON.stringify(response.data?.message || response.data).replace(/^"(.*)"$/, '$1'));
    }
    return response;
  },
  (error) => {
    console.error(error);
    toast.error(`Er ging iets mis:\n${JSON.stringify(error.response?.message || error.response?.data || error.message)}`);
    return error;
  },
);

export default client;
