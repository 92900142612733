import { useQuery } from '@tanstack/react-query';
import { EOLIntegrationTaskOverviewParams, getEolIntegrationDashboard } from '../../services/api/eol-integration/tasks';
import queryTypes from '../../types/hookTypes';

export type ExactOnlineIntegrationDashboardRow = {
  lastAttemptTimestampUnix: number;
  division: string;
  name: string;
  entities: {
    entity: string;
    isSynced: boolean;
    lastEntityTimestamp: number;
    lastAttemptTimestampUnix: number;
    tries: number;
  }[];
};

export const useEOLIntegrationTaskOverview = (params: EOLIntegrationTaskOverviewParams) => {
  return useQuery<ExactOnlineIntegrationDashboardRow[]>([queryTypes.getEOLIntegrationTaskOverview], async () => {
    const builder: {
      [key: string]: ExactOnlineIntegrationDashboardRow;
    } = {};

    const data = await getEolIntegrationDashboard(params);

    data.forEach((row) => {
      if (!builder[row.division]) {
        builder[row.division] = {
          division: row.division,
          name: row.name,
          lastAttemptTimestampUnix: row.lastAttemptTimestampUnix,
          entities: [],
        };
      }
      const newMostRecentTimestamp = row.lastAttemptTimestampUnix > builder[row.division].lastAttemptTimestampUnix;
      if (newMostRecentTimestamp) {
        builder[row.division].lastAttemptTimestampUnix = row.lastAttemptTimestampUnix;
      }

      builder[row.division].entities.push({
        entity: row.entity,
        isSynced: row.isSynced,
        lastEntityTimestamp: row.lastEntityTimestamp,
        tries: row.tries,
        lastAttemptTimestampUnix: row.lastAttemptTimestampUnix,
      });
    });

    const output = Object.values(builder);
    return output;
  });
};
