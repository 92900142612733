import * as XLSX from 'xlsx';

type ExportConfig = {
  sheetName: string;
  fileName: string;
};

export const exportDataToExcel = (data: any[], config: ExportConfig) => {
  // 1. Create a worksheet from the data
  const worksheet = XLSX.utils.json_to_sheet(data);

  // 2. Create a new workbook and append the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, config.sheetName);

  // 3. Trigger the download of the Excel file
  XLSX.writeFile(workbook, config.fileName);
};
