import { Sheet } from '@mui/joy';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useMemo, useState } from 'react';
import Spinner from '../../components/spinner';
import useOutstandingReceivables from '../../hooks/useOutstandingReceivables';
import useFilterStore from '../../stores/filter';
import { formatter } from '../../translations/numberFormatter';
import { OutstandingSalesInvoicesModal } from './outstandingInvoicesModal';

type Props = {};

export const OutstandingReceivablesTable = (props: Props) => {
  const outstandingReceivablesQuery = useOutstandingReceivables();
  const [outstandingInvoiceModalOpen, setOutstandingInvoiceModalOpen] = useState(false);
  const [selectedAdministrationId, setSelectedAdministrationId] = useState<string | null>(null);
  const { divisionSearch } = useFilterStore();

  const rows = useMemo(() => {
    if (!outstandingReceivablesQuery.data) {
      return [];
    }

    return outstandingReceivablesQuery.data.filter((row) => {
      return row.name.toLowerCase().includes(divisionSearch.toLowerCase());
    });
  }, [outstandingReceivablesQuery.data, divisionSearch]);

  if (!outstandingReceivablesQuery.isSuccess) {
    return <Spinner />;
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Naam',
      flex: 1,
    },
    {
      field: 'division',
      headerName: 'Code',
      flex: 0.5,
    },
    {
      field: 'unpaidInvoices',
      headerName: 'Openstaande verkoop facturen',
      flex: 1,
    },
    {
      field: 'totalAmount',
      headerName: 'Openstaand bedrag',
      flex: 1,
      valueFormatter: ({ value }) => formatter.format(-(value as number)),
    },
    {
      field: 'custom_01',
      headerName: 'Gemiddeld openstaand bedrag',
      flex: 1,
      valueGetter: (params) => {
        return -params.row.totalAmount / params.row.unpaidInvoices;
      },
      valueFormatter: ({ value }) => formatter.format(value as number),
    },
    {
      field: 'custom_02',
      headerName: '',
      flex: 1 / 4,
      renderCell: (params) => {
        return <p>details</p>;
      },
    },
  ];

  return (
    <>
      {selectedAdministrationId && (
        <OutstandingSalesInvoicesModal
          open={outstandingInvoiceModalOpen}
          setOpen={setOutstandingInvoiceModalOpen}
          administrationId={selectedAdministrationId}
        />
      )}
      <Sheet>
        <DataGrid
          rows={rows}
          columns={columns}
          getRowId={(params) => params.division}
          onRowClick={(params) => {
            setSelectedAdministrationId(params.row.division);
            setOutstandingInvoiceModalOpen(true);
          }}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10 },
            },
            sorting: {
              sortModel: [{ field: 'pending', sort: 'desc' }],
            },
          }}
        />
      </Sheet>
    </>
  );
};
