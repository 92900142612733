import client from '../../utils/axiosClient';

export type OutstandingInvoices = {
  division: string;
  name: string;
  unpaidInvoices: number;
  totalAmount: number;
};
export const getOutstandingReceivables = async () => {
  const response = await client.get<OutstandingInvoices[]>('/api/receivables/outstanding', {});
  return response.data;
};

type OutstandingInvoicesPerAdministration = {
  invoiceNumber: string;
  accountName: string;
  invoiceAmount: number;
  description: string;
  invoiceDate: string;
};

export const getOutstandingReceivablesByAdministration = async (administrationId: string) => {
  const response = await client.get<OutstandingInvoicesPerAdministration[]>(`/api/receivables/outstanding/${administrationId}`, {});
  return response.data;
};
