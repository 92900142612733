import { Box, CircularProgress, ModalDialog, Stack, Table } from '@mui/joy';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import { Pagination } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import { getRgsCode } from '../../../assets/rgsCodes';
import { useTransactions } from '../../../hooks/transactions/useTransactions';
import useDivision from '../../../hooks/useDivision';
import { formatter } from '../../../translations/numberFormatter';

type TransactionModalProps = {
  administrationId: string;
  startDate: string;
  endDate: string;
  open: boolean;
  rgsCode: string;
  setOpen: (open: boolean) => void;
};

const PAGE_SIZE = 15;

export const TransactionModal = (props: Readonly<TransactionModalProps>) => {
  const { administrationId, startDate, endDate, setOpen, open, rgsCode } = props;
  const [page, setPage] = useState(0);
  const transactionsQuery = useTransactions({
    administrationId,
    rgsCodes: [rgsCode],
    minAmount: 0,
    startDate,
    endDate,
  });
  const divisionQuery = useDivision(administrationId);

  const rows = transactionsQuery.data || [];
  const division = divisionQuery.data;

  return (
    <Modal
      aria-labelledby="close-modal-title"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
      }}
    >
      <ModalDialog
        sx={{
          width: '75vw',
        }}
      >
        <ModalClose variant="soft" color="primary" />
        <Typography component="h2" id="close-modal-title" level="h4" textColor="inherit" fontWeight="lg">
          {`Transacties van ${division?.Description}`}
        </Typography>
        <Typography>{`${rgsCode}: ${getRgsCode(rgsCode)?.descriptionShort}`}</Typography>

        <Stack direction={'column'} gap={3}>
          <Sheet
            className="OrderTableContainer"
            variant="outlined"
            sx={{
              width: '100%',
              borderRadius: 'sm',
              flexShrink: 1,
              overflow: 'auto',
              minHeight: 0,
              maxHeight: '60vh',
            }}
          >
            <Table
              stickyHeader
              stickyFooter
              hoverRow
              sx={{
                '& tr > *:last-child': {
                  textAlign: 'right',
                  paddingLeft: '1rem',
                },
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      width: '50%',
                    }}
                  >
                    Beschrijving
                  </th>
                  <th>Bedrag</th>
                  <th>Bedrag BTW</th>
                  <th>BTW Percentage</th>
                  <th
                    style={{
                      width: '10%',
                    }}
                  >
                    Periode
                  </th>
                </tr>
              </thead>
              <tbody>
                {transactionsQuery.isLoading && <CircularProgress />}
                {transactionsQuery.isError && <Typography>Er ging iets mis tijdens het ophalen van de transacties</Typography>}
                {transactionsQuery.isSuccess &&
                  rows
                    .sort((a, b) => dayjs(a.transactionDate).unix() - dayjs(b.transactionDate).unix())
                    .slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE)
                    .map((row) => {
                      return (
                        <tr key={row.transactionId}>
                          <td>{row.description}</td>
                          <td>{formatter.format(row.transactionAmount)}</td>
                          <td>{formatter.format(row.amountVAT)}</td>
                          <td>{`${Math.round((row.amountVAT / row.transactionAmount) * 100)}%`}</td>
                          <td>{`${row.financialPeriod}/${row.financialYear}`}</td>
                        </tr>
                      );
                    })}
              </tbody>
            </Table>
          </Sheet>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Pagination count={Math.ceil(rows.length / PAGE_SIZE)} color="primary" onChange={(_, page) => setPage(page - 1)} page={page + 1} />
          </Box>
        </Stack>
      </ModalDialog>
    </Modal>
  );
};
