import client from '../../utils/axiosClient';

export const sendNxxtEmail = async (administrationId: string, year: number, emailTo: string) => {
  const response = await client.post(
    `/api/email/${administrationId}/nxxt`,
    {
      year,
      emailTo,
    },
    {},
  );
  return response.data.data;
};
