import { useQuery } from '@tanstack/react-query';
import { getOutstandingReceivables } from '../services/api/getOutstandingReceivables';
import queryTypes from '../types/hookTypes';

const useHook = () => {
  return useQuery([queryTypes.outstandingReceivables], async () => {
    const response = await getOutstandingReceivables();
    return response;
  });
};

export default useHook;
