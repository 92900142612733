import { Button, CircularProgress, Stack, Textarea } from '@mui/joy';
import { useState } from 'react';
import { TCreateNote, createNote } from '../../services/api/notes/createNote';
import { useMe } from '../../hooks/useMe';

export const NoteForm = (props: { administrationId: string; financialPeriod: number; financialYear: number; rgsCode: string; goBack: () => void }) => {
  const { administrationId, financialYear, financialPeriod, rgsCode, goBack } = props;
  const [note, setNote] = useState('');
  const [loading, setLoading] = useState(false);
  const { data: user } = useMe();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (!user) {
        console.error('User data is not available.');
        setLoading(false);
        return;
      }
      const newNote: TCreateNote = {
        administrationId,
        financialPeriod,
        financialYear,
        rgsCode,
        text: note,
        userId: user.id,
      };
      await createNote(newNote);
      goBack();
    } catch (e: any) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack direction="column" spacing={3}>
        <Textarea
          name="Note"
          value={note}
          variant="soft"
          onChange={(e) => setNote(e.target.value)}
          required
          minRows={5}
          placeholder="Schrijf hier je notitie..."
        />
        <Stack direction="row" spacing={2}>
          <Button type="submit" disabled={loading || !note} endDecorator={loading ? <CircularProgress size="sm" /> : null}>
            Opslaan
          </Button>
          <Button type="button" onClick={goBack} color="neutral">
            Cancel
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};
