import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { IconButton, Tooltip, Typography } from '@mui/joy';
import { useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { getRgsCode } from '../../assets/rgsCodes';
import { GetVatRoundingDashboardRow } from '../../services/api/dashboards/getVatRoundingDashboard';
import { VatRoundingGroup } from './tableConfig';
import {
  formatVatRoundingAmount,
  getFuzzyRgsTotalByQuarter,
  getFuzzyRgsTotalByYear,
  getTotalAmountByQuarterByRgsCode,
  getTotalAmountByQuarterByRgsCodes,
  getYearlyTotalByRgsCode,
  getYearlyTotalByRgsCodes,
  quarters,
} from './util';

type VatRoundingDashboardTableProps = {
  group: VatRoundingGroup;
  data: GetVatRoundingDashboardRow[];
  year: number;
  onClick: (rgsCode: string, year: number, quarter: number) => void;
};

export const VatRoundingDashboardTableRow = (props: VatRoundingDashboardTableProps) => {
  const [open, setOpen] = useState(false);
  const { data, group, onClick, year } = props;

  let yearlyTotalAmount = getYearlyTotalByRgsCodes(data, props.group.rgsCodes);
  if (props.group.key === '5b' || props.group.key === '-') {
    yearlyTotalAmount = getFuzzyRgsTotalByYear(data, props.group.rgsCodes);
  }

  // We only want a potential red color for the 'total' group/row
  const yearlyTotalColor = yearlyTotalAmount < 0 && props.group.key === '-' ? 'red' : 'black';

  const expandable = group.key !== '5b' && group.key !== '-';

  return (
    <>
      <tr
        onClick={() => {
          if (!expandable) return;
          setOpen(!open);
        }}
      >
        <td>
          {expandable ? (
            <IconButton aria-label="expand row" variant="plain" color="neutral" size="sm">
              {open ? <ChevronUpIcon className="w-5" /> : <ChevronDownIcon className="w-5" />}
            </IconButton>
          ) : null}
        </td>
        <td>{group.key}</td>
        {quarters.map((quarter) => {
          // Exception for 5b and -, since we have lvl 2 codes that need to be matched, and the data has lvl 4 codes
          if (group.key === '5b' || group.key === '-') {
            const quarterTotalFuzzy = getFuzzyRgsTotalByQuarter(data, group.rgsCodes, quarter);
            const color = quarterTotalFuzzy < 0 ? 'red' : 'black';
            return (
              <td
                style={{
                  color,
                }}
                key={quarter}
              >
                {formatVatRoundingAmount(quarterTotalFuzzy)}
              </td>
            );
          }

          return (
            <td color={yearlyTotalColor} key={quarter}>
              {formatVatRoundingAmount(getTotalAmountByQuarterByRgsCodes(data, group.rgsCodes, quarter))}
            </td>
          );
        })}

        {/* Total column on the right */}
        <td
          style={{
            color: yearlyTotalColor,
          }}
        >
          {formatVatRoundingAmount(yearlyTotalAmount)}
        </td>
      </tr>

      {/* Table that appears when expanding a row, it shows all rgs codes in the group and their amounts */}
      {open && (
        <>
          <tr>
            <td
              colSpan={7}
              style={{
                paddingLeft: '1.9rem',
              }}
            >
              {group.label}
            </td>
          </tr>
          {group.rgsCodes.map((rgsCode) => {
            const commonStyle = { fontSize: 'smaller' };
            const yearlyTotalAmountGroupItem = getYearlyTotalByRgsCode(data, rgsCode);
            const round = false;
            return (
              <tr key={`${rgsCode}-${year}`}>
                <td colSpan={2} style={{ textAlign: 'center', ...commonStyle }}>
                  <Tooltip title={getRgsCode(rgsCode)?.descriptionLong}>
                    <Typography>{rgsCode}</Typography>
                  </Tooltip>
                </td>
                {quarters.map((quarter) => {
                  return (
                    <td onClick={() => onClick(rgsCode, year, quarter)} key={quarter}>
                      {formatVatRoundingAmount(getTotalAmountByQuarterByRgsCode(data, rgsCode, quarter), round)}
                    </td>
                  );
                })}

                {/* Total */}
                <td>{formatVatRoundingAmount(yearlyTotalAmountGroupItem)}</td>
              </tr>
            );
          })}
        </>
      )}
    </>
  );
};
