import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import AddIcon from '@mui/icons-material/Add';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import LibraryAddCheckRoundedIcon from '@mui/icons-material/LibraryAddCheckRounded';
import { Badge, Box, Button, Checkbox, FormControl, IconButton, Modal, ModalDialog, Stack, Tab, TabList, TabPanel, Tabs, Typography } from '@mui/joy';
import ModalClose from '@mui/joy/ModalClose';
import { useEffect, useState } from 'react';
import { useNotes } from '../../hooks/notes/useNotes';
import { useQualityCheckOverrides } from '../../hooks/qualityCheckOverrides/useQualityCheckOverrides';
import { deleteQualityCheckOverride } from '../../services/api/qualityCheckOverrides/deleteQualityCheckOverride';
import { postQualityCheckOverride } from '../../services/api/qualityCheckOverrides/postQualityCheckOverride';
import { NoteForm } from './noteForm';
import { Notes } from './notes';
import TransactionTable from './table';

type Props = {
  division: string;
  year: number;
  month: number;
  checkId: string;
  rgsCode: string;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const months = ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'];

export const TransactionModal = (props: Props) => {
  const { setOpen, checkId, division, month: monthProp, year, open, rgsCode } = props;
  const [monthIndex, setMonthIndex] = useState(monthProp);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState<'note' | 'transactions'>('transactions');
  const notesQuery = useNotes({
    administrationIds: [division],
    year,
    period: monthIndex + 1,
  });
  const qualityCheckOverridesQuery = useQualityCheckOverrides({
    administrationID: division,
  });

  useEffect(() => {
    setMonthIndex(monthProp);
  }, [monthProp]);

  const qualityOverride = qualityCheckOverridesQuery.data?.find((override) => {
    return override.period === monthIndex + 1 && override.year === year && override.checkId.toLowerCase() === checkId.toLowerCase();
  });

  const PeriodNavigator = () => {
    return (
      <Stack direction={'row'} alignItems={'center'} gap={1} marginBottom={'1rem'}>
        <IconButton disabled={monthIndex === 0} onClick={() => setMonthIndex((e) => e - 1)}>
          <ChevronLeftIcon className="w-4 disabled:text-gray-300" />
        </IconButton>
        <Typography>{months[monthIndex]}</Typography>
        <IconButton disabled={monthIndex === 11} onClick={() => setMonthIndex((e) => e + 1)}>
          <ChevronRightIcon className="w-4 disabled:text-gray-300" />
        </IconButton>
      </Stack>
    );
  };

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => setOpen(false)}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <ModalDialog minWidth={'75vw'}>
        <ModalClose variant="soft" color="primary" />
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography level="h2">{`${rgsCode} - ${months[monthIndex]} ${year}`}</Typography>
        </Stack>
        <Tabs defaultValue={'transactions'} sx={{ marginTop: '1rme' }}>
          <TabList>
            <Tab variant="plain" value={'transactions'} color="neutral">
              Transacties
            </Tab>
            <Tab variant="plain" value={'notes'} color="neutral">
              <Badge badgeContent={notesQuery.data?.length}>
                Notities
                <DescriptionRoundedIcon />
              </Badge>
            </Tab>
            <Tab variant="plain" value={'actions'} color="neutral">
              Acties
              <LibraryAddCheckRoundedIcon />
            </Tab>
          </TabList>

          <TabPanel value={'transactions'}>
            <Stack alignContent={'space-between'} justifyContent={'space-between'} sx={{ minHeight: '40vh' }}>
              <TransactionTable division={division} checkId={checkId} month={monthIndex} year={year} />
              <Stack justifyContent={'center'} alignItems={'center'}>
                <PeriodNavigator />
              </Stack>
            </Stack>
          </TabPanel>
          <TabPanel value={'actions'}>
            <Stack alignContent={'space-between'} justifyContent={'space-between'} sx={{ minHeight: '40vh' }}>
              <FormControl>
                <Checkbox
                  disabled={loading}
                  label="Periode handmatig goedkeuren (Verplichte notitie moet aanwezig zijn)"
                  checked={!!qualityOverride}
                  onChange={async (event) => {
                    setLoading(true);
                    if (event.target.checked) {
                      await postQualityCheckOverride({
                        checkId: checkId.toLowerCase(),
                        period: monthIndex + 1,
                        year: year,
                        administrationId: division,
                      });
                      await qualityCheckOverridesQuery.refetch();
                      setLoading(false);
                      return;
                    }

                    if (!qualityOverride) {
                      setLoading(false);
                      console.error('No quality override found');
                      return;
                    }
                    await deleteQualityCheckOverride({ id: qualityOverride.id.toLowerCase() });
                    await qualityCheckOverridesQuery.refetch();
                    setLoading(false);
                  }}
                />
              </FormControl>
              <Stack justifyContent={'center'} alignItems={'center'}>
                <PeriodNavigator />
              </Stack>
            </Stack>
          </TabPanel>
          <TabPanel value={'notes'}>
            <Stack alignContent={'space-between'} justifyContent={'space-between'} sx={{ minHeight: '40vh' }}>
              <Box>
                <Stack gap={3}>
                  <Notes administrationIds={[division]} year={year} period={monthIndex + 1} />
                  {tab === 'note' && (
                    <NoteForm
                      administrationId={division}
                      financialPeriod={monthIndex + 1}
                      financialYear={year}
                      rgsCode={rgsCode}
                      goBack={async () => {
                        setTab('transactions');
                        await notesQuery.refetch();
                      }}
                    />
                  )}
                </Stack>

                <Box marginTop={'1rem'} />

                <Stack justifyContent={'end'} alignItems={'end'}>
                  {tab !== 'note' && (
                    <Button
                      sx={{ height: '1rem', width: '6rem' }}
                      startDecorator={<AddIcon />}
                      onClick={() => {
                        setTab('note');
                      }}
                    >
                      Notitie
                    </Button>
                  )}
                </Stack>
              </Box>
              <Stack justifyContent={'center'} alignItems={'center'}>
                <PeriodNavigator />
              </Stack>
            </Stack>
          </TabPanel>
        </Tabs>
      </ModalDialog>
    </Modal>
  );
};
