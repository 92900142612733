export function getLastFinishedQuarterYear(): number {
  const now = new Date();
  const currentMonth = now.getMonth(); // Month is 0-indexed (0 = January, 11 = December)
  const currentYear = now.getFullYear();

  // Determine the last finished quarter
  if (currentMonth < 3) {
    // If current month is January, February, or March, the last finished quarter is Q4 of the previous year
    return currentYear - 1;
  } else {
    // Otherwise, the last finished quarter is in the current year
    return currentYear;
  }
}
