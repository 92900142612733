import client from '../../../utils/axiosClient';

export type EOLIntegrationTaskOverview = {
  division: string;
  entity: string;
  isSynced: boolean;
  tries: number;
  lastEntityTimestamp: number;
  lastAttemptTimestampUnix: number;
  name: string;
};

export type EOLIntegrationTaskOverviewParams = {};

export const getEolIntegrationDashboard = async (params?: EOLIntegrationTaskOverviewParams) => {
  const response = await client.get<EOLIntegrationTaskOverview[]>('/api/eol-integration', {
    params,
  });
  return response.data;
};
