import InfoIcon from '@mui/icons-material/Info';
import MoreVert from '@mui/icons-material/MoreVert';
import { Alert, Box } from '@mui/joy';
import Dropdown from '@mui/joy/Dropdown';
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Stack from '@mui/joy/Stack';
import { useMemo } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import toast from 'react-hot-toast';
import DivisionSearch from '../../components/divisionSearch';
import KpiCard from '../../components/KpiCard';
import { PageHeader } from '../../components/pageHeader';
import useOutstandingReceivables from '../../hooks/useOutstandingReceivables';
import { exportDataToExcel } from './export';
import { OutstandingReceivablesTable } from './table';
import { formatter } from '../../translations/numberFormatter';

export const OutstandingInvoicesPage = () => {
  const outstandingReceivablesQuery = useOutstandingReceivables();

  const totalPendingInvoices = useMemo(() => {
    if (!outstandingReceivablesQuery.data) {
      return 0;
    }

    return outstandingReceivablesQuery.data.reduce((acc, row) => {
      return acc + row.unpaidInvoices;
    }, 0);
  }, [outstandingReceivablesQuery.data]);

  const totalOutstandingAmount = useMemo(() => {
    if (!outstandingReceivablesQuery.data) {
      return 0;
    }

    return outstandingReceivablesQuery.data.reduce((acc, row) => {
      return acc + -row.totalAmount;
    }, 0);
  }, [outstandingReceivablesQuery.data]);

  const averageOutstandingAmount = useMemo(() => {
    if (!outstandingReceivablesQuery.data) {
      return 0;
    }

    return totalOutstandingAmount / totalPendingInvoices;
  }, [outstandingReceivablesQuery.data]);

  const onClickExport = () => {
    if (!outstandingReceivablesQuery.data) {
      toast.error('Geen data om te exporteren');
      return;
    }

    exportDataToExcel(outstandingReceivablesQuery.data, {
      fileName: 'openstaande-verkoop-facturen.xlsx',
      sheetName: 'openstaande-verkoop-facturen',
    });
  };

  return (
    <Stack direction="column" gap={3}>
      <Alert variant="soft" color="primary" startDecorator={<InfoIcon />}>
        {`Dit dashboard is in ontwikkeling`}
      </Alert>
      <PageHeader>Openstaande verkoop facturen (ouder dan 2 maanden)</PageHeader>

      <Stack direction={'column'} gap={3}>
        <Stack direction={'row'} gap={3} justifyContent={'space-between'} alignItems={'end'}>
          <DivisionSearch />

          <Dropdown>
            <MenuButton slots={{ root: IconButton }} slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}>
              <MoreVert />
            </MenuButton>
            <Menu>
              <MenuItem onClick={onClickExport}>Export</MenuItem>
            </Menu>
          </Dropdown>
        </Stack>

        <Stack gap={3} direction={'row'}>
          <KpiCard
            key={`total_pending_invoices`}
            value={new Intl.NumberFormat('nl-NL').format(totalPendingInvoices).toString()}
            label={'Openstaande verkoop facturen (ouder dan 2 maanden)'}
            onClick={() => {}}
          />
          <KpiCard
            key={`total_pending_invoices_amount`}
            value={formatter.format(totalOutstandingAmount)}
            label={'Totaal openstaand bedrag'}
            onClick={() => {}}
          />
          <KpiCard
            key={`average_pending_invoices_amount`}
            value={formatter.format(averageOutstandingAmount)}
            label={'Gemiddeld openstaand bedrag'}
            onClick={() => {}}
          />
        </Stack>

        {/* Content */}
        <OutstandingReceivablesTable />
      </Stack>
    </Stack>
  );
};
