import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { Chip, IconButton } from '@mui/joy';
import Table from '@mui/joy/Table';
import dayjs from 'dayjs';
import { useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { ExactOnlineIntegrationDashboardRow } from '../../hooks/eolIntegration/useTaskOverview';

type VatRoundingDashboardTableProps = {
  row: ExactOnlineIntegrationDashboardRow;
};

export const ExactOnlineIntegrationDashboardTableRow = (props: VatRoundingDashboardTableProps) => {
  const [open, setOpen] = useState(false);
  const { row } = props;

  const formattedLastUpdateTimestamp = dayjs.unix(row.lastAttemptTimestampUnix / 1000).fromNow();

  const syncedEntitites = row.entities.filter((entity) => entity.isSynced).length;
  const totalEntities = row.entities.length;

  const chipText = `${syncedEntitites}/${totalEntities}`;
  let color = 'success';
  if (syncedEntitites < totalEntities) {
    color = 'warning';
  }
  if (syncedEntitites < totalEntities - 2) {
    color = 'danger';
  }

  return (
    <>
      <tr
        onClick={() => {
          setOpen(!open);
        }}
      >
        <td>
          <IconButton aria-label="expand row" variant="plain" color="neutral" size="sm">
            {open ? <ChevronUpIcon className="w-5" /> : <ChevronDownIcon className="w-5" />}
          </IconButton>
        </td>
        <td>{row.name}</td>
        <td>{row.division}</td>
        <td>{formattedLastUpdateTimestamp}</td>
        <td>
          <Chip color={color as any}>{chipText}</Chip>
        </td>
      </tr>
      {open && (
        <tr>
          <td colSpan={1} />
          <td colSpan={4}>
            <Table>
              <thead>
                <tr>
                  <th>Entiteit</th>
                  <th>Laatste update</th>
                  <th>Gefaalde pogingen</th>
                  <th>Bijgewerkt</th>
                </tr>
              </thead>
              <tbody>
                {row.entities.map((entity) => {
                  const entityChipColor = entity.isSynced ? 'success' : 'danger';
                  const entityChipText = entity.isSynced ? 'Bijgewerkt' : 'Niet bijgewerkt';
                  return (
                    <tr key={`${row.division}-${entity.entity}`}>
                      <td>{entity.entity}</td>
                      <td>{dayjs.unix(entity.lastAttemptTimestampUnix / 1000).fromNow()}</td>
                      <td>{entity.tries}</td>
                      <td>
                        <Chip color={entityChipColor as any}>{entityChipText}</Chip>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </td>
        </tr>
      )}
    </>
  );
};
