import MoreVert from '@mui/icons-material/MoreVert';
import { Modal, ModalDialog, Stack, Typography } from '@mui/joy';
import Dropdown from '@mui/joy/Dropdown';
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import ModalClose from '@mui/joy/ModalClose';
import 'react-circular-progressbar/dist/styles.css';
import toast from 'react-hot-toast';
import useDivision from '../../../hooks/useDivision';
import useOutstandingReceivablesByAdministration from '../../../hooks/useOutstandingReceivablesByAdministration';
import { formatter } from '../../../translations/numberFormatter';
import { exportDataToExcel } from '../export';
import { OutstandingReceivablesByDivisionTable } from './table';

type OutstandingSalesInvoicesModalProps = {
  administrationId: string;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const OutstandingSalesInvoicesModal = (props: OutstandingSalesInvoicesModalProps) => {
  const { setOpen, open, administrationId } = props;
  const divisionQuery = useDivision(administrationId);
  const invoiceQuery = useOutstandingReceivablesByAdministration(administrationId);

  const onClickExport = () => {
    if (!invoiceQuery.data) {
      toast.error('Geen data om te exporteren');
      return;
    }

    exportDataToExcel(invoiceQuery.data, {
      fileName: `open-verk-fact-${administrationId}.xlsx`,
      sheetName: `open-verk-fact-${administrationId}`,
    });
  };

  const totalAmount = invoiceQuery.data?.reduce((acc, curr) => acc + -curr.invoiceAmount, 0);

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => setOpen(false)}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <ModalDialog minWidth={'60vw'}>
        <ModalClose variant="soft" color="primary" />
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography level="h2">{`Verkoop facturen ouder dan 2 maanden voor ${divisionQuery.data?.Description ?? '...'}`}</Typography>
        </Stack>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography level="body-md">{`Totaal bedrag: ${totalAmount ? formatter.format(totalAmount) : '...'}`}</Typography>
          <Dropdown>
            <MenuButton slots={{ root: IconButton }} slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}>
              <MoreVert />
            </MenuButton>
            <Menu
              sx={{
                zIndex: 1000000,
              }}
            >
              <MenuItem onClick={onClickExport}>Export</MenuItem>
            </Menu>
          </Dropdown>
        </Stack>

        <OutstandingReceivablesByDivisionTable administrationId={administrationId} />
      </ModalDialog>
    </Modal>
  );
};
