import { useQuery } from '@tanstack/react-query';
import { TGetNotesParams, getNotes } from '../../services/api/notes/getNotes';
import queryTypes from '../../types/hookTypes';

export const useNotes = (params: TGetNotesParams) => {
  return useQuery([queryTypes.notes, params.administrationIds, params.period, params.year], async () => {
    const notes = await getNotes(params);
    return notes.filter((note) => note.financialYear === params.year);
  });
};
