import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Box, Checkbox, Chip, FormControl, FormLabel, Grid, Stack } from '@mui/joy';
import Table from '@mui/joy/Table';
import { Pagination, Typography } from '@mui/material';
import { useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import DivisionSearch from '../../components/divisionSearch';
import Spinner from '../../components/dynamicSpinner';
import { PageHeader } from '../../components/pageHeader';
import { useEOLIntegrationTaskOverview } from '../../hooks/eolIntegration/useTaskOverview';
import useFilterStore from '../../stores/filter';
import { ExactOnlineIntegrationDashboardTableRow } from './tableRow';

const PAGE_SIZE = 15;

export const IntegrationDashboardExact = () => {
  const taskOverviewQuery = useEOLIntegrationTaskOverview({});
  const { divisionSearch } = useFilterStore();
  const [filterComplete, setFilterComplete] = useState(false);
  const [page, setPage] = useState(1);

  if (!taskOverviewQuery.isSuccess) {
    return <Spinner />;
  }

  const rows = taskOverviewQuery.data
    .filter((row) => {
      if (divisionSearch) {
        return row.name?.toLowerCase().includes(divisionSearch.toLowerCase());
      }
      return true;
    })
    .filter((row) => {
      if (filterComplete) {
        return row.entities.some((entity) => !entity.isSynced);
      }
      return true;
    });

  const totalPages = Math.ceil(rows.length / PAGE_SIZE);
  const pageRows = rows.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);
  const syncMetadata = taskOverviewQuery.data.reduce(
    (acc, row) => {
      const syncedCount = row.entities.filter((entity) => entity.isSynced).length;
      const totalCount = row.entities.length;
      return {
        syncedCount: acc.syncedCount + syncedCount,
        totalCount: acc.totalCount + totalCount,
      };
    },
    {
      syncedCount: 0,
      totalCount: 0,
    },
  );
  const syncScore = Math.floor((syncMetadata.syncedCount / syncMetadata.totalCount) * 100);
  const isFullySynced = taskOverviewQuery.data.every((row) => row.entities.every((entity) => entity.isSynced));
  let syncScoreChipColor = 'success';
  if (syncScore < 50) {
    syncScoreChipColor = 'danger';
  } else if (syncScore < 100) {
    syncScoreChipColor = 'warning';
  }
  const hideTable = filterComplete && isFullySynced;

  return (
    <Stack gap={5}>
      <PageHeader>Integration Dashboard Exact</PageHeader>
      <Grid container gap={2}>
        <Grid xs={12}>
          <Stack direction={'row'} justifyContent={'space-between'} gap={1}>
            <Stack gap={3} direction={'row'}>
              <DivisionSearch />
              <FormControl>
                <FormLabel>Filter onvolledige administraties</FormLabel>
                <Checkbox
                  label="aan/uit"
                  onClick={() => {
                    setFilterComplete((checked) => !checked);
                  }}
                  defaultChecked={filterComplete}
                />
              </FormControl>
            </Stack>
            <Box>
              <Chip color={syncScoreChipColor as any}>Sync score: {syncScore}%</Chip>
            </Box>
          </Stack>
        </Grid>
        <Grid xs={12}>
          {filterComplete && isFullySynced && (
            <Stack alignItems={'center'} direction={'column'} sx={{ my: '8rem' }} gap={1}>
              <CheckCircleRoundedIcon />
              <Typography>Alle administraties zijn uptodate!</Typography>
            </Stack>
          )}

          {!hideTable && (
            <>
              <Table
                hoverRow
                sx={{
                  '& tr': {
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  },
                }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        width: '7%',
                      }}
                    />
                    <th
                      style={{
                        width: '40%',
                      }}
                    >
                      Naam
                    </th>
                    <th
                      style={{
                        width: '20%',
                      }}
                    >
                      Code
                    </th>
                    <th>Laatst bijgewerkt</th>
                    <th
                      style={{
                        width: '10%',
                      }}
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {pageRows.map((row) => {
                    return <ExactOnlineIntegrationDashboardTableRow key={row.division} row={row} />;
                  })}
                </tbody>
              </Table>
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                <Pagination count={totalPages} color="primary" onChange={(_, page) => setPage(page)} page={page} />
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </Stack>
  );
};
