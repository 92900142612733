import 'react-circular-progressbar/dist/styles.css';
import { GetVatRoundingDashboardRow } from '../../services/api/dashboards/getVatRoundingDashboard';
import { formatter } from '../../translations/numberFormatter';

export const sumArray = (array: number[]) => {
  return array.reduce((acc, value) => acc + value, 0);
};

export const formatVatRoundingAmount = (amount: number, round = true) => {
  if (amount === 0) {
    return '-';
  }

  // Format and remove decimals
  if (round) {
    return formatter.format(Math.round(amount)).split(',')[0];
  }
  return formatter.format(amount);
};

export const getTotalAmountByQuarterByRgsCodes = (data: GetVatRoundingDashboardRow[], rgsCodes: string[], quarter: number) => {
  const total = data.reduce((acc, row) => {
    if (rgsCodes.includes(row.rgsCode)) {
      acc += row.quarters[quarter - 1];
    }
    return acc;
  }, 0);

  return total;
};

export const getTotalAmountByQuarterByRgsCode = (data: GetVatRoundingDashboardRow[], rgsCode: string, quarter: number) => {
  const total = data.find((row) => row.rgsCode === rgsCode)?.quarters[quarter - 1];

  return total ?? 0;
};

export const getYearlyTotalByRgsCode = (data: GetVatRoundingDashboardRow[], rgsCode: string) => {
  const total = data.reduce((acc, row) => {
    if (row.rgsCode === rgsCode) {
      let rgsTotal = sumArray(row.quarters);
      return acc + rgsTotal;
    }

    return acc;
  }, 0);

  return total;
};

export const getYearlyTotalByRgsCodes = (data: GetVatRoundingDashboardRow[], rgsCodes: string[]) => {
  const total = rgsCodes.reduce((acc, rgsCode) => {
    const rgsTotal = getYearlyTotalByRgsCode(data, rgsCode);
    return acc + rgsTotal;
  }, 0);

  return total;
};

export const getFuzzyRgsTotalByQuarter = (data: GetVatRoundingDashboardRow[], rgsCodes: string[], quarter: number) => {
  const values = data.reduce((acc, row) => {
    const hasSamePrefix = rgsCodes.some((code) => row.rgsCode.startsWith(code));
    if (hasSamePrefix) {
      acc += row.quarters[quarter - 1];
    }
    return acc;
  }, 0);

  return values;
};

export const getFuzzyRgsTotalByYear = (data: GetVatRoundingDashboardRow[], rgsCodes: string[]) => {
  const total = quarters.reduce((acc, quarter) => {
    const quarterTotal = getFuzzyRgsTotalByQuarter(data, rgsCodes, quarter);
    return acc + quarterTotal;
  }, 0);

  return total;
};

export const quarters = [1, 2, 3, 4];
