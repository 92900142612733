import { Modal, ModalDialog } from '@mui/joy';
import { useEffect, useState } from 'react';
import Form from './form';
import Overview from './overview';

type Props = {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
};

export type Check = {
  ID: string;
  RgsCodes: string;
};

export default function CheckModal(props: Props) {
  const { isOpen, setOpen } = props;
  const [selectedCheck, setSelectedCheck] = useState<Check | null>(null);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (!showForm) setSelectedCheck(null);
  }, [showForm]);

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={isOpen}
      onClose={() => setOpen(false)}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <ModalDialog
        sx={{
          width: '50vw',
        }}
      >
        {showForm ? (
          <Form selectedCheck={selectedCheck} cancel={() => setShowForm(false)} />
        ) : (
          <Overview setselectedCheck={setSelectedCheck} goToForm={() => setShowForm(true)} setOpen={setOpen} />
        )}
      </ModalDialog>
    </Modal>
  );
}
