import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Card, CardContent, IconButton, Stack } from '@mui/joy';
import { Typography } from '@mui/material';
import { useState } from 'react';
import { getRgsCode } from '../../assets/rgsCodes';
import { ConfirmationDialog } from '../../components/confirmationDialog';
import { useUsers } from '../../hooks/useUsers';
import { deleteNote } from '../../services/api/notes/deleteNote';
import { TNote } from '../../services/api/notes/getNotes';

const getUsernameFromEmail = (email: string) => {
  return email
    .split('@')[0]
    .replace('.', ' ')
    .replace(/^./, (char) => char.toUpperCase());
};

export const Note = ({ note, refetch }: { note: TNote; refetch: () => void }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const date = new Date(Number.parseInt(note.createdAt, 10)).toLocaleString('nl-NL');
  const formattedDate = date.slice(0, date.indexOf(','));
  const rgsCodeData = getRgsCode(note.rgsCode);
  const rgsDescriptionShort = rgsCodeData?.descriptionShort || '';

  const usersQuery = useUsers();
  const users = usersQuery.data || [];

  const user = users.find((u) => u.id === note.userId);
  const userName = getUsernameFromEmail(user?.email ?? '');

  return (
    <>
      <ConfirmationDialog
        content="Weet je zeker dat je deze notitie wilt verijderen?"
        onClose={() => {
          setOpen(false);
        }}
        onConfirm={async () => {
          try {
            setLoading(true);
            await deleteNote({
              noteId: note.id,
            });
            await refetch();
            setOpen(false);
          } catch (error) {
            console.error(error);
          } finally {
            setLoading(false);
          }
        }}
        open={open}
        title="Notitie verwijderen"
        disabled={loading}
      />
      <Card variant="soft">
        <CardContent>
          <Stack direction={'row'} justifyContent={'space-between'} gap={1}>
            <Typography
              variant="subtitle1"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                color: 'text.secondary',
                fontWeight: 100,
              }}
            >
              <Typography component="span" color="primary.main" fontWeight="bold">
                {`${note.financialYear}/${note.financialPeriod}`}
              </Typography>
              <Typography component="span" color="divider">
                •
              </Typography>
              <Typography component="span" color="text.primary">
                {note.rgsCode}
              </Typography>
              <Typography component="span" color="divider">
                •
              </Typography>
              <Typography component="span" color="text.primary">
                {rgsDescriptionShort}
              </Typography>
            </Typography>

            <IconButton
              loading={loading}
              onClick={() => setOpen(true)}
              variant="plain"
              sx={{
                padding: 0,
                margin: 0,
                marginTop: '-1rem',
                marginRight: '-1rem',
              }}
            >
              <CloseRoundedIcon sx={{ width: '1rem' }} />
            </IconButton>
          </Stack>
          <Stack direction={'row'} gap={1}>
            <Typography>{note.text}</Typography>
          </Stack>
          <Typography variant="subtitle2" marginTop={'.5rem'}>{`Aangemaakt: ${formattedDate} door: ${userName || 'Onbekend'}`}</Typography>
        </CardContent>
      </Card>
    </>
  );
};
