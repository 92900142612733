import { useQuery } from '@tanstack/react-query';
import { getOutstandingReceivablesByAdministration } from '../services/api/getOutstandingReceivables';
import queryTypes from '../types/hookTypes';

const useHook = (administrationId: string) => {
  return useQuery([queryTypes.outstandingReceivables, administrationId], async () => {
    const response = await getOutstandingReceivablesByAdministration(administrationId);
    return response;
  });
};

export default useHook;
